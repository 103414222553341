
import React, { useState } from "react";

interface CreatorListProps {
  data;
}

const CreatorListPhoneFc: React.FC<CreatorListProps> = (props) => {
  const { data } = props;
  console.log(data, "data========");
  const [imageUrl, setImageUrl] = useState<string>("");
  const handleImage = (url: string) => {
    setImageUrl(url);
  };

  return (
    <div className="container">
      {data?.map((item) => [
        <div className="creator-list" key={item.id}>
          <div className="creator-item">
            {/* avatar */}
            <div className="justify-between" style={{ position: "relative" }}>
              <div className="justify-between">
                <span className="author-avator">
                  <img src={item?.creatorInfo?.avatar} alt="" />
                </span>
                &nbsp;&nbsp;
                <div>
                  <div>{item.creatorInfo.nickname}</div>
                  <div
                    className="inline-block tag"
                    style={{ color: "#777", fontSize: "0.9rem" }}
                  >
                    {item?.creatorInfo?.career}
                    {item?.creatorInfo.career && <>&nbsp;&nbsp;&nbsp;</>}
                    {item?.tags?.map((i, index) => {
                      return (
                        <>
                          <span>
                            {i.name}
                            {item.tags.length != index + 1 ? (
                              <> | &nbsp;</>
                            ) : (
                              <></>
                            )}
                          </span>
                        </>
                      );
                    })}
                  </div>
                  <div
                    className="inline-block"
                    dangerouslySetInnerHTML={{
                      __html: item.creatorInfo.description,
                    }}
                    style={{ color: "#999999", fontSize: "0.9rem" }}
                  ></div>
                </div>
              </div>
              <div style={{ position: "absolute", right: "0", top: "-5px" }}>
                {/* <div className="contact pointer">{item.contact}</div> */}
              </div>
            </div>
            {/* <div style={{   textAlign: "right",}}>
              <div   style={{ color: "#777", fontSize: "0.9rem" }}>
                {item?.workQuoteInfo?.map((i) => [
                  <div>
                    {i.work}
                    <span style={{ color: "red" }}>&nbsp;¥{i.price.price}</span>
                  </div>,
                ])}
              </div>
            </div> */}
            {/* video */}
            <div className="video-info">
              <ul className="video-list">
                {item?.urlWorks?.map((url: string) => [
                  <li>
                    <div className="pointer">
                      <img
                        className="w-100 img-fluid"
                        src={url}
                        alt=""
                        onClick={() => handleImage(url)}
                        data-bs-toggle="modal"
                        data-bs-target="#showImg"
                      />
                    </div>
                  </li>,
                ])}
              </ul>
            </div>
            <div
              className="video-info"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <a
                key={0}
                className="nav-link nav-color-0 pointer"
                style={{
                  border: "1px solid",
                  padding: "5px 5px",
                  borderRadius: "5px",
                  height: "40px",
                }}
                href="http://j.lidebiji.com/user/register"
              >
                打招呼
              </a>
            </div>
          </div>
        </div>,
      ])}
      <div
        className="modal fade"
        id="showImg"
        tabIndex={-1}
        aria-labelledby="showImg"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          style={{
            width: "100%",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            marginTop: "55%",
          }}
        >
          <img src={imageUrl} alt={""} style={{ width: "100%" }}></img>
        </div>
      </div>
    </div>
  );
};

export default CreatorListPhoneFc;
